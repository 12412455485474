import React, { useState, /*useEffect*/ } from "react";
{/*import { withRouter } from "react-router-dom";*/}
import "./MainNavbar.scss";

import { Link } from "gatsby";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBIcon,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavItem,
	MDBNavbarToggler,
	MDBCollapse,
	MDBDropdown,
	MDBDropdownToggle,
	MDBDropdownMenu,
	MDBDropdownItem,
} from "mdbreact";

import Logo from "../../../assets/images/navbar/img-logo.svg";

export default function MainNavbar() {
	const [isAcessoRapidoOpen, setIsAcessoRapidoOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<MDBContainer fluid>
			{/* <MDBRow className="acessibilidade-nav">
				<MDBCol>
					<MDBContainer>
						<MDBRow>
							<MDBCol>
								<ul className="acessibilidade-lista">
									<li>
										<a
											accessKey="1"
											tabIndex="1"
											href="#menu-principal"
											className="acessibilidade-lk"
											title="Menu Principal"
										>
											Menu Principal
										</a>
									</li>
									<li>
										<a
											accessKey="3"
											tabIndex="3"
											href="#conteudo-pagina"
											className="acessibilidade-lk"
											title="Conteúdo Central"
										>
											Conteúdo Central
										</a>
									</li>
									<li>
										<a
											accessKey="a"
											tabIndex="4"
											href="#"
											className="acessibilidade-lk"
											title="Alto Contraste"
											onClick={() => {
												if (typeof window !== "undefined") {
													window.localStorage.setItem("altoContraste", 
														window.localStorage.getItem("altoContraste") === "true");

													if(window.localStorage.getItem("altoContraste") === "true") {
														document.getElementsByTagName("head")[0].append('<link rel="stylesheet" href="/assets/css/high-contrast.css" id="hccss"/>');
													} else {
														document.getElementById("hccss").remove();
													}
												}
											}}
										>
											Alto Contraste
										</a>
									</li>
									<li>
										<a
											accessKey="i"
											tabIndex="5"
											href="https://www.feevale.br/institucional/acessibilidade"
											className="acessibilidade-lk"
											title="Acessibilidade"
											target="_blank"
											rel="noreferrer"
										>
											Acessibilidade
										</a>
									</li>
									<li>
										<a
											href="https://www.feevale.br/sos"
											tabIndex="6"
											target="_blank"
											rel="noreferrer"
										>
											<i
												className="fa fa-angle-double-right"
												aria-hidden="true"
											></i>{" "}
											SOS Feevale
										</a>
									</li>
								</ul>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBCol>
			</MDBRow> */}
			<MDBRow>
				<MDBCol className="bgprimary-gradient">
					<MDBContainer>
						<MDBRow>
							<MDBCol>
								<MDBNavbar className="shadow-none" dark expand="md">
									<MDBNavbarBrand>
										<Link to="/">
											<img
												src={Logo}
												alt="Escola de Aplicação Feevale"
												className="img-fluid pt-2 pb-3"
												style={{ maxWidth: "180px", height: "70px" }}
											/>
										</Link>
									</MDBNavbarBrand>
									<MDBNavbarToggler
										onClick={() => {
											setIsAcessoRapidoOpen(!isAcessoRapidoOpen);
											setIsMenuOpen(false);
										}}
									/>
									<MDBCollapse isOpen={isAcessoRapidoOpen} navbar>
										<MDBNavbarNav right>
											<MDBNavItem className="my-auto px-2 py-2">
												<a
													href="https://www.feevale.br/ambientevirtual"
													target="_blank"
													className="text-white text-decoration-none font-weight-normal"
													rel="noreferrer"
												>
													<MDBIcon icon="fas fa-laptop" size="lg" /> Ambiente
													virtual
												</a>
											</MDBNavItem>
											<MDBNavItem className="my-auto px-2 py-2">
												<a
													href="https://www.feevale.br/institucional/biblioteca"
													target="_blank"
													className="text-white text-decoration-none font-weight-normal"
													rel="noreferrer"
												>
													<MDBIcon icon="book-reader" size="lg" /> Biblioteca
												</a>
											</MDBNavItem>
											<MDBNavItem className="my-auto px-2 py-2">
												<a
													href="https://espaco.feevale.br"
													target="_blank"
													className="text-white text-decoration-none font-weight-normal"
													rel="noreferrer"
												>
													<MDBIcon icon="user-circle" size="lg" /> Espaço
													Feevale
												</a>
											</MDBNavItem>
											<MDBNavItem className="my-auto px-2 py-2">
												<a
													href="https://www.feevale.br/impressaoweb"
													target="_blank"
													className="text-white text-decoration-none font-weight-normal"
													rel="noreferrer"
												>
													<MDBIcon icon="print" size="lg" /> Impressão web
												</a>
											</MDBNavItem>
											<MDBNavItem className="my-auto px-2 py-2">
												<a
													href="https://www.feevale.br/portalaluno"
													target="_blank"
													className="text-white text-decoration-none font-weight-normal"
													rel="noreferrer"
												>
													<MDBIcon icon="user-graduate" size="lg" /> Portal do aluno
												</a>
											</MDBNavItem>
											<MDBNavItem className="my-auto px-2 py-2">
												<a
													href="https://outlook.com/feevale.br"
													target="_blank"
													className="text-white text-decoration-none font-weight-normal"
													rel="noreferrer"
												>
													<MDBIcon icon="mail-bulk" size="lg" /> Webmail
												</a>
											</MDBNavItem>
										</MDBNavbarNav>
									</MDBCollapse>
								</MDBNavbar>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="bg-light">
					<MDBContainer>
						<MDBRow>
							<MDBCol>
								<MDBNavbar className="shadow-none text-center" expand="md">
									<MDBNavbarToggler
										className="w-100"
										onClick={() => {
											setIsMenuOpen(!isMenuOpen);
											setIsAcessoRapidoOpen(false);
										}}
									>
										<span className="colorLetter h6">
											MENU PRINCIPAL{" "}
											<MDBIcon icon={isMenuOpen ? "times" : "chevron-down"} />
										</span>
									</MDBNavbarToggler>
									<MDBCollapse isOpen={isMenuOpen} navbar>
										<MDBNavbarNav
											center
											style={{ color: "#808285" }}
											className="py-2"
										>
											<MDBNavItem>
												<MDBDropdown>
													<MDBDropdownToggle
														className="text-decoration-none"
														nav
													>
														<span className="mr-2 text-center colorLetter">
															A Escola <MDBIcon icon="caret-down" />
														</span>
													</MDBDropdownToggle>
													<MDBDropdownMenu
														style={{ color: "#808285" }}
														className="p-0"
													>
														<Link
															to="/a-escola/quem-somos"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem
																className="dropdown-hovered colorLetter"
																activeClassName="dropdown-hovered"
															>
																Quem somos
															</MDBDropdownItem>
														</Link>
														<Link
															to="/a-escola/desafios-e-festivais"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Calendário (desafios e festivais)
															</MDBDropdownItem>
														</Link>
														<Link
															to="/a-escola/bem-vindo"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Bem-vindo 2024
															</MDBDropdownItem>
														</Link>
														<Link
															to="/a-escola/ambientes-de-aprendizagem"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Ambientes de aprendizagem
															</MDBDropdownItem>
														</Link>
														<Link
															to="/a-escola/internacionalizacao"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Internacionalização
															</MDBDropdownItem>
														</Link>
														<Link
															to="/a-escola/formaturas"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Formaturas
															</MDBDropdownItem>
														</Link>
														<Link
															to="/a-escola/para-o-estudante"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Para o estudante
															</MDBDropdownItem>
														</Link>
													</MDBDropdownMenu>
												</MDBDropdown>
											</MDBNavItem>
											<MDBNavItem>
												<MDBDropdown>
													<MDBDropdownToggle
														className="text-decoration-none"
														nav
													>
														<span className="mr-2 text-center colorLetter">
															Etapas de aprendizagem{" "}
															<MDBIcon icon="caret-down" />
														</span>
													</MDBDropdownToggle>
													<MDBDropdownMenu className="mx-auto p-0">
														<Link
															to="/etapas-de-aprendizagem/educacao-infantil"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Educação Infantil
															</MDBDropdownItem>
														</Link>
														<Link
															to="/etapas-de-aprendizagem/ensino-fundamental"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Ensino Fundamental
															</MDBDropdownItem>
														</Link>
														<Link
															to="/etapas-de-aprendizagem/ensino-medio"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Ensino Médio
															</MDBDropdownItem>
														</Link>
														<Link
															to="/etapas-de-aprendizagem/cursos-tecnicos"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Cursos Técnicos
															</MDBDropdownItem>
														</Link>
													</MDBDropdownMenu>
												</MDBDropdown>
											</MDBNavItem>
											<MDBNavItem>
												<MDBDropdown>
													<MDBDropdownToggle
														className="text-decoration-none"
														nav
													>
														<span className="mr-2 text-center colorLetter">
															Contraturno <MDBIcon icon="caret-down" />
														</span>
													</MDBDropdownToggle>
													<MDBDropdownMenu className="mx-auto p-0">
														<Link
															to="/contraturno/atividades-extracurriculares"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Atividades extracurriculares
															</MDBDropdownItem>
														</Link>
														<Link
															to="/contraturno/oficinas"
															className="p-0 m-0 text-decoration-none"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Oficinas
															</MDBDropdownItem>
														</Link>
														<a
															href="https://www.feevale.br/cursos-e-eventos/centro-de-idiomas/english-kids-and-teens"
															className="p-0 m-0 text-decoration-none"
															target="_blanck"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Idiomas
															</MDBDropdownItem>
														</a>
														<a
															href="https://www.feevale.br/servicos/atividades-fisicas-e-aquaticas/natacao"
															className="p-0 m-0 text-decoration-none"
															target="_blanck"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Natação
															</MDBDropdownItem>
														</a>
														<a
															href="https://www.feevale.br/institucional/cultura/movimento-coral-feevale"
															className="p-0 m-0 text-decoration-none"
															target="_blanck"
														>
															<MDBDropdownItem className="dropdown-hovered colorLetter">
																Movimento Coral Feevale
															</MDBDropdownItem>
														</a>
													</MDBDropdownMenu>
												</MDBDropdown>
											</MDBNavItem>
											<MDBNavItem className="mt-2">
												<Link
													to="/acontece-na-escola"
													className="p-0 m-0 text-decoration-none colorLetter"
												>
													Acontece
												</Link>
											</MDBNavItem>
											<MDBNavItem className="mt-2">
												<Link
													to="/beneficios"
													className="p-0 m-0 text-decoration-none colorLetter"
												>
													Benefícios
												</Link>
											</MDBNavItem>
											<MDBNavItem className="mt-2">
												<Link
													to="/rematriculas"
													className="text-decoration-none colorLetter"
												>
													Rematrículas
												</Link>
											</MDBNavItem>
											<MDBNavItem className="pt-3 pb-1 p-md-0">
												<Link
													to="/matriculas"
													className="btn btn-block bgsecondary-gradient text-white mx-md-3 py-2 px-2 rounded-pill border-0"
												>
													SEJA ESTUDANTE
												</Link>
											</MDBNavItem>
										</MDBNavbarNav>
									</MDBCollapse>
								</MDBNavbar>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
				</MDBCol>
			</MDBRow>
			<MDBRow>
				<MDBCol className="bgprimary-gradient" style={{ height: 10 }}></MDBCol>
			</MDBRow>
		</MDBContainer>
	);
}
