import React from "react";
import "./MainFooter.scss";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBFooter,
	MDBIcon,
	MDBBtn,
} from "mdbreact";

import LogoFooter from "../../assets/images/footer/img-logo-footer.svg";

export default function MainFooter() {
	return (
		<MDBFooter color="bgprimary-gradient" className="mt-5">
			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<p className="h2 py-4 font-weight-bold text-md-left text-center">
							Converse com a gente
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol md={8} className="py-3 text-md-left text-center">
						<MDBRow>
							<MDBCol className="py-2 py-md-0">
								<p className="h4 font-weight-bold">Atendimento online</p>
								<p>
									Mantemos nosso atendimento de forma online,
									<br />
									das 7h às 12h e das 13h30min às 18h, de segunda a sexta, pelos seguintes
									canais:
								</p>
							</MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol col={12} md={6} className="py-2 py-md-0">
								<p className="h4 font-weight-bold">Secretaria</p>
								<p>
									<a
										className="text-white normal"
										href="mailto:secretariaescola@feevale.br"
									>
										secretariaescola@feevale.br
									</a>
									<br />
									<a className="text-white normal" href="tel:05135868800">
										3586.8800 (ramal 7108)
									</a>
								</p>
							</MDBCol>
							<MDBCol col={12} md={6} className="py-2 py-md-0">
								<p className="h4 font-weight-bold">
									Coordenação Pedagógica e Direção
								</p>
								<p>
									<a
										className="text-white normal"
										href="mailto:secretariaescola@feevale.br"
									>
										nap@feevale.br
									</a>
									<br />
									<a className="text-white normal" href="tel:05135868800">
										3586.8800 (ramal 7108)
									</a>
								</p>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol md={4}>
						<a
							href="https://feevale.plusoftomni.com.br/api/chatsite/site/XVtMgYsroRAUYWhHsIeTq1qWYJ0aD5___CqiN1WNxxu8gwjKa2jtLA==/client"
							rel="noreferrer"
							target="_blank"
							className="mt-2 text-decoration-none"
						>
							<button
								className="btn btn-footer btn-block p-2 text-md-left text-center shadow-none"
								style={{ textTransform: "none" }}
							>
								<MDBIcon far icon="comments" size="lg" />
								&nbsp;&nbsp;Converse pelo chat
							</button>
						</a>

						<a
							href="https://www.feevale.br/falecomafeevale"
							rel="noreferrer"
							target="_blank"
							className="mt-2 text-decoration-none"
						>
							<button
								className="btn btn-footer btn-block p-2 text-md-left text-center shadow-none"
								style={{ textTransform: "none" }}
							>
								<MDBIcon icon="align-justify" size="lg" />
								&nbsp;&nbsp;Fale com a Feevale
							</button>
						</a>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p className="text-center text-white py-3">
							Av. Dr. Maurício Cardoso, 510 - Bairro Hamburgo Velho, Novo
							Hamburgo/RS.
							<br />
							(51) 3586.8800, ramais 7108 e 7112.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="text-center pb-3">
						<a href="https://www.feevale.br" target="_blank" rel="noreferrer">
							<img
								src="https://www.feevale.br/s/conteudo/b3af5fc4-f40d-42cd-bedb-86291657abf3/Feevale-selo55anos-bco.svg"
								className="img-fluid"
								style={{ width: 250 }}
								alt="Universidade Feevale"
								title="Universidade Feevale"
							/>
						</a>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</MDBFooter>
	);
}
//imagem do logo da feevale na pasta img, escrita em svg