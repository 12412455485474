import React, { useState } from "react";
import axios from "axios";

import { MDBRow, MDBCol } from "mdbreact";

import { Formik, Field, Form, ErrorMessage } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import paises from "../../data/form/paises.json";

export default function FormInteresse({ textBox = null }) {
	/* State para retorno do campo telefone */
	const [phone, setPhone] = useState();
	const [inputCountry, setInputCountry] = useState({});

	let etapasAprendizagem = [
		{ id: "EducacaoInfantil", lable: "Educação infantil" },
		{ id: "EnsinoFundamental", lable: "Ensino fundamental" },
		{ id: "EnsinoMedio", lable: "Ensino médio" },
		{ id: "CursosTecnicos", lable: "Cursos técnicos" },
	];

	return (
		<>
			<Formik
				initialValues={{
					name: "",
					email: "",
					aceitaComunicacao: false,
				}}
				onSubmit={async function (values, actions, errors) {
					// Validação do numero de telefone
					if (
						!(
							(inputCountry.format.match(/\./g) || []).length == phone.length
						) ||
						!phone
					) {
						actions.setStatus({
							sent: false,
							msg:
								`O número de telefone deve ter ` +
								(inputCountry.format.match(/\./g) || []).length +
								" dígitos.",
						});
						return false;
					}

					let etapasAprendizagemSelecionado = [];

					if (values.EducacaoInfantil) {
						etapasAprendizagemSelecionado.push("Educação infantil");
					}
					if (values.EnsinoFundamental) {
						etapasAprendizagemSelecionado.push("Ensino fundamental");
					}
					if (values.EnsinoMedio) {
						etapasAprendizagemSelecionado.push("Ensino médio");
					}
					if (values.CursosTecnicos) {
						etapasAprendizagemSelecionado.push("Cursos técnicos");
					}

					axios
						.post(`https://servico.feevale.br/leads/v2/escola`, {
							evento: "Registro de interesse",
							nome: values.name,
							email: values.email,
							telefone: phone,
							etapasAprendizagem: etapasAprendizagemSelecionado,
							aceitaComunicacao: values.aceitaComunicacao,
							key: "27DFBDE2-A94D-4E48-A178-CE6F851641FE",
						})
						.then((response) => {
							if (response.status === 200) {
								actions.setStatus({
									sent: true,
									msg: "Interesse registrado com sucesso!",
								});
							}
						})
						.catch((error) => {
							if (error.response) {
								if (error.response.status === 400) {
									// Caso o e-mail seja inválido.
									errors = "Por favor, insira um e-mail válido.";
								}
							}

							actions.setStatus({
								sent: false,
								msg: `Um erro ocorreu. Por favor, tente novamente mais tarde.`,
							});
						});
				}}
			>
				{({ isSubmitting, status }) => (
					<Form>
						{textBox && <h6 className="mb-4 text-center">{textBox}</h6>}
						<Field
							placeholder="Nome completo"
							className="my-2 form-control rounded-0"
							name="name"
							type="text"
							required
						/>
						<Field
							placeholder="Seu e-mail"
							className="my-2 form-control rounded-0"
							name="email"
							type="email"
							required
						/>
						{/* <ErrorMessage name="email" component="div" /> */}

						<PhoneInput
							localization={paises}
							inputProps={{
								required: true,
							}}
							masks={{ br: "(..) .....-...." }}
							placeholder="Seu número"
							searchPlaceholder="Pesquisar"
							searchNotFound="Pais não encontrado"
							isValid={(value, inputNumber) => {
								setInputCountry(inputNumber);
								return true;
							}}
							country={"br"}
							enableSearch={true}
							inputClass="w-100 rounded-0"
							dropdownClass="text-dark"
							countryCodeEditable={false}
							disableSearchIcon={true}
							value={phone}
							onChange={(value) => {
								setPhone(value);
							}}
						/>

						{/* <Field
							name="quemEVc"
							as="select"
							className="my-2 form-control rounded-0"
							placeholder="Selecione"
							required
						>
							<option value="">Selecione</option>
							<option value="Responsável legal">Responsável legal</option>
							<option value="Estudante">Estudante</option>
						</Field> */}

						<div className="text-center pb-2 pt-4">
							<h6 className="pb-2 pb-md-0 fgsecondary-gradient font-weight-bold">
								Etapas de aprendizagem
							</h6>
							<MDBRow>
								{etapasAprendizagem.map((etapaAprendizagem) => (
									<MDBCol className="col-6 col-md-6 my-auto">
										<label>
											<Field type="checkbox" name={etapaAprendizagem.id} />
											<span className="my-auto">
												{" "}
												{etapaAprendizagem.lable}
											</span>
										</label>
									</MDBCol>
								))}
							</MDBRow>
						</div>

						{status && status.msg && (
							<p
								className={`alert ${
									status.sent ? "alert-success" : "alert-danger"
								}`}
							>
								{status.msg}
							</p>
						)}

						<button
							type="submit"
							className="comercial mt-2 p-2 rounded-0"
							disabled={status ? status.sent : status}
							style={{ fontSize: "1rem" }}
						>
							<span>Registre seu interesse</span>
						</button>
						<div className="text-center text-md-left mt-2 p-0">
							<label style={{fontSize:"15px"}}>
								<Field type="checkbox" name="aceitaComunicacao" /> Eu concordo em receber comunicações em diferentes canais sobre a Escola de Aplicação Feevale.
							</label>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
}
