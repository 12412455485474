import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { MDBIcon } from "mdbreact";

export default function Avisos() {
	const [avisos, setAvisos] = useState(null);

	useEffect(() => {
		axios
			.get(`https://servico.feevale.br/site/v2/avisos/escola`)
			.then((res) => {
				setAvisos(res.data);
			});
	}, []);

	// Define os botões de avanço e retorno do carousel
	const ButtonGroup = ({ next, previous }) => {
		if (avisos.length <= 1) {
			return <></>;
		} else {
			return (
				<>
					<MDBIcon
						className="text-white position-absolute"
						style={{
							zIndex: 1000,
							top: "20%",
							left: "4%",
							backgroundColor: "transparent",
							cursor: "pointer",
						}}
						icon="chevron-left"
						size="2x"
						onClick={() => previous()}
					/>
					<MDBIcon
						className="text-white position-absolute"
						style={{
							zIndex: 1000,
							top: "20%",
							right: "4%",
							backgroundColor: "transparent",
							cursor: "pointer",
						}}
						icon="chevron-right"
						size="2x"
						onClick={() => next()}
					/>
				</>
			);
		}
	};

	const CustomDot = ({ onMove, index, onClick, active }) => {
		// onMove means if dragging or swiping in progress.
		// active is provided by this lib for checking if the item is active or not.
		if (avisos.length <= 1) {
			return <></>;
		} else {
			return (
				<li
					className={active ? "active" : "inactive"}
					onClick={() => onClick()}
				></li>
			);
		}
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1025 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 465 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	if (avisos != null && avisos.length > 0) {
		return (
			<div
				className="pb-3 pb-md-0"
				style={{
					backgroundColor: "#cc0000",
					minHeight: avisos.length <= 1 ? 50 : 60,
					position: "relative",
				}}
			>
				<Carousel
					swipeable={true}
					draggable={false}
					showDots={avisos.length <= 1 ? false : true}
					responsive={responsive}
					ssr={true} // means to render carousel on server-side.
					infinite={avisos.length <= 1 ? false : true}
					autoPlay={avisos.length <= 1 ? false : true}
					autoPlaySpeed={4000}
					keyBoardControl={true}
					transitionDuration={500}
					arrows={false}
					renderDotsOutside={true}
					renderButtonGroupOutside={true}
					customButtonGroup={<ButtonGroup />}
					customDot={avisos.length <= 1 ? <></> : <CustomDot />}
					containerClass="carousel-container m-lg-0 mx-5 h-min-50px"
					dotListClass="carousel-indicators"
					itemClass="text-center p-2"
				>
					{avisos?.map((aviso, index) => (
						<div>
							<a
								href={
									aviso.Redirecionamento == null
										? "https://avisos.feevale.br/avisos/" + aviso.Url
										: aviso.Redirecionamento
								}
								target="_blank"
								className="text-decoration-none text-white h6"
								rel="noreferrer"
							>
								<MDBIcon icon="exclamation-triangle" className="pr-2 " />
								{aviso.Title}
							</a>
						</div>
					))}
				</Carousel>
			</div>
		);
	}

	return <></>;
}
