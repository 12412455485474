import React, { useState } from "react";
import PropTypes from "prop-types";
import { MDBModal, MDBModalBody, MDBCloseIcon } from "mdbreact";
import FormInteresse from "../FormInteresse/FormInteresse";

export default function ModalInteresse({ children }) {
	ModalInteresse.propTypes = {
		children: PropTypes.object.isRequired,
	};

	const [basicModal, setBasicModal] = useState(false);

	const toggleShow = () => setBasicModal(!basicModal);

	if (
		typeof window !== "undefined" &&
		window.location.pathname.startsWith("/matriculas")
	) {
		return (
			<>
				<MDBModal isOpen={basicModal} toggle={setBasicModal} size="lg">
					<MDBCloseIcon className="text-right mr-4 mt-3" onClick={toggleShow} />
					<MDBModalBody className="px-4 px-md-5 pb-5 ">
						<h4 className="fgsecondary-gradient text-center">
							Registre seu interesse!
						</h4>
						<h6 className="mb-3 text-center">
							Antes de sair, realize seu cadastro e mantenha-se informado
							<br />
							sobre as novidades da Escola de Aplicação
						</h6>
						<FormInteresse />
					</MDBModalBody>
				</MDBModal>
				<div onMouseLeave={toggleShow}>{children}</div>
			</>
		);
	}

	return <div>{children}</div>;
}
